<template>
  <div class="container">
    <div class="row d-flex justify-content-around align-items-center">
      <div class="col-3 text-left">
        <img class="w-75" src="/assets/images/logo.svg" />
      </div>
      <div class="col-6">
        <h2 class="header-title mb-0 mt-3 text-center text-uppercase">Recibo de Ingreso</h2>
      </div>
      <div class="col-3 text-right">
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-5">
        <table class="table border">
            <tbody>
              <tr>
                <th class="font-weight-bold"><h5>Fecha de Pago</h5></th>
                <th>{{ payment.date | moment('DD-MM-YYYY') }}</th>
                <th rowspan="3" class="text-center border">
                  <p>Monto recibido</p>
                  <h1>{{ payment.amount | formatMoney }}</h1>
                </th>
              </tr>
              <tr>
                <td class="font-weight-bold"><h5>Método de Pago</h5></td>
                <td>{{ payment.payment_method | payment_method }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold"><h5>Número de Referencia</h5></td>
                <td>{{ payment.reference | uppercase }}</td>
              </tr>
            </tbody>
        </table>
      </div>
      <div class="col-12 mt-5">
        <div class="row">
          <div class="col-12">
            <h4>Facturado a:</h4>
          </div>
          <div v-if="payment.invoice.record.client" class="col-12 mt-2">
            <h4 v-if="payment.invoice.record.client.type_id === 2">
              {{ payment.invoice.record.client.commercial_name | uppercase }}
            </h4>
            <h4 v-else>
              {{ payment.invoice.record.client.first_name | uppercase }} {{ payment.invoice.record.client.last_name | uppercase }}
            </h4>
          </div>
        </div>
      </div>
      <hr>
      <div class="col-12 mt-5">
        <h5>Pago realizado a</h5>
      </div>
      <div class="col-12">
        <table class="table border">
          <thead class="bg-secondary text-black">
            <tr>
              <th>Factura #</th>
              <th>Fecha de facturación</th>
              <th>Monto</th>
              <th>Pago Realizado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Fact #{{ payment.invoice.id }}</td>
              <td>{{ payment.invoice.due_date | moment('DD-MM-YYYY') }}</td>
              <td>{{ payment.invoice.total | formatMoney }}</td>
              <td>{{ payment.amount | formatMoney }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-5 d-flex justify-content-center align-items-center flex-column">
      <div class="mt-5 col-3 text-center">
        <a onclick="window.print()" href="#" class="d-print-none btn btn-info d-block">Imprimir</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PaymentReceipt',
  props: ['id'],
  data () {
    return {
      payment: {}
    }
  },
  methods: {
    ...mapActions({
      fetchPayment: 'Payment/fetchPayment'
    })
  },
  mounted () {
    this.fetchPayment({
      id: this.id
    })
      .then(response => {
        this.payment = response.data
      })
  }
}
</script>
